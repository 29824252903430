import './scss/bootstrap.scss';

// Import jQuery and assign it to the global scope
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

// Import Bootstrap
import 'bootstrap';
import 'owl.carousel';

// Import other dependencies
import WOW from 'wowjs';
import 'jquery.easing';
import 'waypoints/lib/noframework.waypoints.js';
import lightbox from 'lightbox2';
import 'owl.carousel';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Import other CSS files
import 'lightbox2/dist/css/lightbox.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css/animate.min.css'; // For WOW.js animations

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

import 'jquery.easing';

// Import any custom CSS
import './css/style.css';

// Initialize WOW.js
new WOW.WOW().init();

// document.getElementById("spinner").style.display = 'none';
(function ($) {
  "use strict";

  // Spinner
  var spinner = function () {
      setTimeout(function () {
          if ($('#spinner').length > 0) {
              $('#spinner').removeClass('show');
          }
      }, 1);
  };
  spinner(0);
  
  
  // Initiate the wowjs
  const WOW = require('wowjs');

  window.wow = new WOW.WOW({
      live: false
  });
  
  window.wow.init();  

  // Sticky Navbar
  $(window).scroll(function () {
      if ($(this).scrollTop() > 45) {
          $('.nav-bar').addClass('sticky-top shadow-sm').css('top', '0px');
      } else {
          $('.nav-bar').removeClass('sticky-top shadow-sm').css('top', '-100px');
      }
  });


  // Header carousel
  $(".header-carousel").owlCarousel({
      animateOut: 'fadeOut',
      items: 1,
      margin: 0,
      stagePadding: 0,
      autoplay: true,
      smartSpeed: 500,
      dots: true,
      loop: true,
      nav : true,
      navText : [
          '<i class="bi bi-arrow-left"></i>',
          '<i class="bi bi-arrow-right"></i>'
      ],
  });



  // testimonial carousel
  $(".testimonial-carousel").owlCarousel({
      autoplay: true,
      smartSpeed: 1500,
      center: false,
      dots: false,
      loop: true,
      margin: 25,
      nav : true,
      navText : [
          '<i class="fa fa-arrow-right"></i>',
          '<i class="fa fa-arrow-left"></i>'
      ],
      responsiveClass: true,
      responsive: {
          0:{
              items:1
          },
          576:{
              items:1
          },
          768:{
              items:2
          },
          992:{
              items:2
          },
          1200:{
              items:2
          }
      }
  });

 // Back to top button
 $(window).scroll(function () {
  if ($(this).scrollTop() > 300) {
      $('.back-to-top').fadeIn('slow');
  } else {
      $('.back-to-top').fadeOut('slow');
  }
  });
  $('.back-to-top').click(function () {
      $('html, body').animate({scrollTop: 0}, 1500, 'easeInOutExpo');
      return false;
  });


})($);

const r = document.querySelector(':root');
r.style.setProperty('--bs-body', 'lightblue');
r.style.setProperty('--bs-light', '#f1d7d5');
r.style.setProperty('--bs-dark', '#3b3333');
r.style.setProperty('--bs-primary', '#e06946');
r.style.setProperty('--bs-btn-color', '#fff');
r.style.setProperty('--bs-btn-bg', '#e06946');
r.style.setProperty('--bs-btn-border-color', '#e06946');
r.style.setProperty('--bs-btn-hover-color', '#fff');
r.style.setProperty('--bs-btn-hover-bg', '#a2482e');
r.style.setProperty('--bs-btn-hover-border-color', '#0a58ca');
r.style.setProperty('--bs-btn-focus-shadow-rgb', '#e06946');
r.style.setProperty('--bs-accordion-btn-focus-box-shadow', 'rgba(217, 85, 9, 0.125)', 'important');
r.style.setProperty('--bs-btn-active-color', '#fff');
r.style.setProperty('--bs-btn-active-bg', '#0a58ca');
r.style.setProperty('--bs-btn-active-border-color', '#0a53be');
r.style.setProperty('--bs-btn-active-shadow', 'inset 0 3px 5px rgba(217, 85, 9, 0.5)');
r.style.setProperty('--bs-btn-disabled-color', '#fff');
r.style.setProperty('--bs-btn-disabled-bg', '#e06946');
r.style.setProperty('--bs-btn-disabled-border-color', '#e06946');
r.style.setProperty('--bs-primary-rgb', '#a2482e');
r.style.setProperty('--bs-accordion-active-color', '#a2482e');

// Select all elements with the 'accordion-button' class
const accordionButtons = document.querySelectorAll('.accordion-button');

// Loop through each element and set the CSS variable
accordionButtons.forEach(button => {
  button.style.setProperty('--bs-accordion-btn-focus-box-shadow', '0 0 0 0.25rem rgba(217, 85, 9, 0.125)');
});